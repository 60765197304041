
































































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { LoginIcon } from '@vue-hero-icons/outline';
import store from '@/app/store';

import Auth from '../api/auth';

extend('required', required);
extend('email', email);

export default defineComponent({
    name: 'Login',
    components: { LoginIcon, ValidationObserver, ValidationProvider },
    setup(props, { root }) {
        const path = root.$route.params.path || '/'; // Check if user was redirected here, so we redirect him to the correct page
        const loginForm = ref<any>(null);
        const message = ref(null);
        const loading = ref(false);
        const credentials = reactive({
            email: '',
            password: '',
        });

        const login = async () => {
            message.value = null;
            if (await loginForm.value.validate()) {
                loading.value = true;
                Auth.login(credentials)
                    .then(async () => {
                        const { data } = await Auth.user();
                        store.commit.auth.SET_USER(data);
                        store.dispatch.auth.loadFeatures();
                        store.dispatch.dataModel.loadDomains();
                        store.dispatch.notificationEngine.fetchNotifications();
                        root.$router.push(path);
                        loading.value = false;
                    })
                    .catch((err) => {
                        message.value = err.response.data.message;

                        loading.value = false;
                    });
            }
        };

        return {
            credentials,
            loginForm,
            login,
            message,
            loading,
        };
    },
});
