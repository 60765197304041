var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col min-h-screen"},[_c('div',{staticClass:"px-4 pt-6 sm:px-6 lg:px-8"},[_c('nav',{staticClass:"relative flex items-center justify-start h-10"},[_c('div',{staticClass:"flex items-center flex-grow flex-shrink-0"},[_c('div',{staticClass:"flex items-center justify-between w-full sm:mt-4"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"w-auto h-16 sm:h-20",attrs:{"src":require("@/app/assets/img/SPARCS-logo-on-white.png"),"alt":""}})])],1)])])]),_c('div',{staticClass:"mx-auto mt-16 tracking-wide md:mt-24 lg:mt-32 text-primary-600"},[_c('h2',{staticClass:"mt-2 text-3xl font-extrabold text-center text-neutral-900"},[_vm._v("Sign up")]),_c('p',{staticClass:"mt-2 text-sm text-center text-neutral-600"},[_vm._v(" Or "+_vm._s(' ')+" "),(_vm.useKeycloak && !_vm.isAuthenticated)?_c('button',{staticClass:"font-semibold text-primary-600 hover:text-primary-500",on:{"click":_vm.login}},[_vm._v(" Log in ")]):(!_vm.isAuthenticated)?_c('router-link',{staticClass:"font-semibold text-primary-600 hover:text-primary-500",attrs:{"to":{ name: 'login' }}},[_vm._v(" Log in ")]):_vm._e()],1)]),_c('div',{staticClass:"flex max-w-xs mx-auto my-8 bg-white rounded-lg shadow-md md:max-w-lg"},[_c('div',{staticClass:"w-screen px-4 py-4"},[_c('validation-observer',{ref:"loginForm",staticClass:"flex flex-col space-y-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-wrap space-y-2 md:flex-row"},[_c('div',{staticClass:"w-full px-2 mt-2 md:w-1/2"},[_c('validation-provider',{attrs:{"vid":"firstName","name":"First name","rules":{ required: true, regex: /^[a-zA-Z0-9-. ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only",attrs:{"for":"firstName"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.firstName),expression:"userData.firstName"}],staticClass:"relative block w-full px-3 py-2 border rounded-md appearance-none text-neutral-900 placeholder-neutral-500 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm",attrs:{"id":"firstName","name":"firstName","type":"text","placeholder":"First Name"},domProps:{"value":(_vm.userData.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "firstName", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-2 md:w-1/2"},[_c('validation-provider',{attrs:{"vid":"lastName","name":"Last name","rules":{ required: true, regex: /^[a-zA-Z0-9-. ]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only",attrs:{"for":"lastName"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.lastName),expression:"userData.lastName"}],staticClass:"relative block w-full px-3 py-2 border rounded-md appearance-none text-neutral-900 placeholder-neutral-500 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm",attrs:{"id":"lastName","name":"lastName","type":"text","placeholder":"Last Name"},domProps:{"value":(_vm.userData.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "lastName", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-2 md:w-1/2"},[_c('validation-provider',{attrs:{"vid":"username","name":"Username","rules":{ required: true, regex: /^[a-zA-Z0-9-.]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only",attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.username),expression:"userData.username"}],staticClass:"relative block w-full px-3 py-2 border rounded-md appearance-none text-neutral-900 placeholder-neutral-500 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm",attrs:{"id":"username","name":"username","type":"text","placeholder":"Username"},domProps:{"value":(_vm.userData.username)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "username", $event.target.value)},function($event){_vm.isRegistrationOpen ? (_vm.showMessage = false) : null}]}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]),(_vm.showMessage && _vm.isRegistrationOpen)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"w-full px-2 md:w-1/2"},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.email),expression:"userData.email"}],staticClass:"relative block w-full px-3 py-2 border rounded-md appearance-none text-neutral-900 placeholder-neutral-500 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm",attrs:{"id":"email","name":"email","type":"email","placeholder":"Email"},domProps:{"value":(_vm.userData.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "email", $event.target.value)},function($event){_vm.isRegistrationOpen ? (_vm.showMessage = false) : null}]}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-2 md:w-1/2"},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only",attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.password),expression:"userData.password"}],staticClass:"relative block w-full px-3 py-2 border rounded-md appearance-none text-neutral-900 placeholder-neutral-500 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm",attrs:{"id":"password","name":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.userData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "password", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full px-2 md:w-1/2"},[_c('validation-provider',{attrs:{"vid":"passwordRepeat","name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"sr-only",attrs:{"for":"passwordRepeat"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.passwordRepeat),expression:"userData.passwordRepeat"}],staticClass:"relative block w-full px-3 py-2 border rounded-md appearance-none text-neutral-900 placeholder-neutral-500 border-neutral-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm",attrs:{"id":"passwordRepeat","name":"passwordRepeat","type":"password","placeholder":"Confirm Password"},domProps:{"value":(_vm.userData.passwordRepeat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData, "passwordRepeat", $event.target.value)}}}),_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex flex-col items-center justify-between lg:flex-row lg:space-x-2"},[(_vm.enableTermsAndConditions)?_c('validation-provider',{attrs:{"name":"Terms and Conditions","rules":{ termsAndConditionsIsRequired: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('terms-and-conditions',{attrs:{"errors":errors},model:{value:(_vm.userData.termsAndConditions),callback:function ($$v) {_vm.$set(_vm.userData, "termsAndConditions", $$v)},expression:"userData.termsAndConditions"}})]}}],null,true)}):_c('div'),_c('button',{staticClass:"relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md disabled:pointer-events-none disabled:opacity-75 bg-primary-600 group hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",attrs:{"disabled":invalid || _vm.loading},on:{"click":_vm.register}},[_c('span',{staticClass:"absolute inset-y-0 left-0 flex items-center pl-3"},[_c('UserAddIcon',{staticClass:"w-5 h-5 text-primary-500 group-hover:text-primary-400",attrs:{"aria-hidden":"true"}})],1),_vm._v(" Register ")])],1),(!_vm.isRegistrationOpen)?_c('div',{staticClass:"flex self-center px-4 text-sm text-red-700 lg:self-end lg:flex-row"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }