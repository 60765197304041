































































































































































































import { defineComponent, reactive, ref, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, confirmed, min, regex } from 'vee-validate/dist/rules';
import Auth from '@/modules/auth/api/auth';
import { UserAddIcon } from '@vue-hero-icons/outline';
import store from '@/app/store';
import Keycloak from '@/modules/auth/api/keycloak';
import { TermsAndConditions } from '../components';

extend('required', required);
extend('regex', regex);
extend('termsAndConditionsIsRequired', {
    ...required,
    message: 'You need to read and accept the terms and conditions before proceeding',
});
extend('email', email);
extend('confirmed', { ...confirmed, message: 'Confirm Password does not match.' });
extend('min', { ...min, message: 'Password must be longer than or equal to 8 characters.' });

export default defineComponent({
    name: 'Register',
    components: { ValidationProvider, ValidationObserver, TermsAndConditions, UserAddIcon },
    setup(props, { root }) {
        const loading = ref(false);
        const message = ref<any>(null);
        const showMessage = ref(false);
        const enableTermsAndConditions = false; // turn to true if you want to show terms and conditions component

        const isLoggingIn = ref(false);
        const isAuthenticated = computed(() => store.getters.auth.isAuthenticated);
        const useKeycloak = computed(() => Keycloak.isEnabled());

        const userDataTemplate = computed(() => {
            if (enableTermsAndConditions) {
                return {
                    firstName: '',
                    lastName: '',
                    username: '',
                    email: '',
                    password: '',
                    passwordRepeat: '',
                    termsAndConditions: false,
                };
            }
            return {
                firstName: '',
                lastName: '',
                username: '',
                email: '',
                password: '',
                passwordRepeat: '',
            };
        });

        const userData = reactive(userDataTemplate.value);
        const register = () => {
            loading.value = true;
            Auth.register(userData)
                .then(async () => {
                    root.$router.push({ name: 'login' });
                    loading.value = false;
                })
                .catch((e) => {
                    message.value =
                        e.response.data.message === 'Registration is closed'
                            ? 'Registration is closed'
                            : 'Username or Email already exists.';
                    showMessage.value = true;
                    loading.value = false;
                });
        };

        const login = async () => {
            isLoggingIn.value = true;
            try {
                await Keycloak.login();
            } catch (e) {
                (root as any).$toastr.e((e as any).message, 'Error');
            } finally {
                isLoggingIn.value = false;
            }
        };

        const keycloakAuthentication = async () => {
            if (store.getters.auth.isAuthenticated) {
                root.$router.push({ name: 'home', hash: '' });
            } else {
                isLoggingIn.value = true;
                try {
                    await Keycloak.login();
                } catch (e) {
                    (root as any).$toastr.e((e as any).message, 'Error');
                } finally {
                    isLoggingIn.value = false;
                }
            }
        };
        if (
            Keycloak.isEnabled() &&
            document.location.hash.length > 0 &&
            document.location.hash.match(/^#.*code=[a-zA-Z0-9]*/g)
        ) {
            keycloakAuthentication();
        }

        const isRegistrationOpen = computed(() => message.value !== 'Registration is closed');

        return {
            enableTermsAndConditions,
            loading,
            message,
            showMessage,
            register,
            userData,
            isRegistrationOpen,
            useKeycloak,
            isAuthenticated,
            isLoggingIn,
            login,
        };
    },
});
